<script>
import AkLabel from "../general/AkLabel";

export default {
  components: { AkLabel },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
      //default: "col-md-4"
    },
    validator: {
      type: Object,
      required: false,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    isInvalid() {
      return (
        this.validator !== undefined &&
        this.validator.$invalid &&
        this.submitted
      );
    },
    isRequired() {
      return (
        this.validator !== undefined && this.validator.required !== undefined
      );
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.submitted
      );
    },
    isValidatorMinMaxLength() {
      return (
        this.validator !== undefined &&
        (this.validator.minLength !== undefined ||
          this.validator.maxLength !== undefined) &&
        (this.validator.minLength.$invalid ||
          this.validator.maxLength.$invalid) &&
        this.submitted
      );
    },
  },
};
</script>

<template>
  <div :class="this.className">
    <FloatLabel>
      <InputText
        :disabled="this.disabled"
        type="text"
        v-model="currentValue"
        :class="{ 'p-invalid': isInvalid }"
      />
      <ak-label :required="isRequired">{{ this.label }}</ak-label>
    </FloatLabel>
    <small v-if="isValidatorRequired" class="p-error">
      Le champ {{ this.label }} est requis</small
    >
  </div>
</template>
