<script>
import AkLabel from "@components/general/AkLabel";

export default {
  components: { AkLabel },
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4",
    },
    validator: {
      type: Object,
      required: false,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    isInvalid() {
      return (
        this.validator !== undefined &&
        this.validator.$invalid &&
        this.submitted
      );
    },
    isRequired() {
      return (
        this.validator !== undefined && this.validator.required !== undefined
      );
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.submitted
      );
    },
    getMode() {
      return this.mode ? this.mode : undefined;
    },
    getCurrency() {
      return this.mode === "currency" ? "EUR" : undefined;
    },
  },
};
</script>

<template>
  <div :class="this.className">
    <FloatLabel>
      <InputNumber
        :disabled="this.disabled"
        type="text"
        class="form-control"
        :mode="getMode"
        :currency="getCurrency"
        v-model="currentValue"
        :prefix="this.prefix"
        :suffix="this.suffix"
        :min="this.min"
        :max="this.max"
        :class="{ 'p-invalid': isInvalid }"
      />
      <ak-label :required="isRequired">{{ this.label }}</ak-label>
    </FloatLabel>
    <small v-if="isValidatorRequired" class="p-error">
      Le champ est requis</small
    >
  </div>
</template>
