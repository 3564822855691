<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import ModuleAccessService from "./service/ModuleAccessService";
import store from "@store";

export default {
  data() {
    return {
      moduleAccesses: [],
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Tableau de bord",
          items: [
            {
              label: "Accueil",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
          ],
        },
        {
          label: "Gestion",
          icon: "pi pi-fw pi-sitemap",
          items: [
            /*{label: 'Connexion', icon: 'pi pi-fw pi-id-card', to: '/public/login'},*/
            {
              label: "Utilisateurs",
              icon: "pi pi-fw pi-id-card",
              to: "/crud/user",
            },
            {
              label: "Spectacles",
              icon: "pi pi-fw pi-check-square",
              to: "/crud/spectacle",
            },
            { label: "Salles", icon: "pi pi-fw pi-home", to: "/crud/salle" },
            /* { label: "Prix", icon: "pi pi-fw pi-euro", to: "/prices" },
             {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
            {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "/invalidstate"},
            {label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
            {label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
            {label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
            {label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
            {label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
            {label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
            {label: "Media", icon: "pi pi-fw pi-image", to: "/media"},
            {label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu'},
            {label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
            {label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
            {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
            {label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'}, */
          ],
        },
      ],
    };
  },

  moduleAccessService: null,
  created() {
    this.moduleAccessService = new ModuleAccessService();
  },

  mounted() {
    this.moduleAccessService.getAllModuleAccesses().then((data) => {
      this.moduleAccesses = data;
      store.dispatch("saveModuleAccesses", this.moduleAccesses);

      //build menu items with module accesses
      //If module access with moduleName "prices" is in the list and access = true, add it to items
      if (
        this.moduleAccesses.some((e) => e.moduleName === "prices" && e.access)
      ) {
        this.menu[1].items.push({
          label: "Prix",
          icon: "pi pi-fw pi-euro",
          to: "/prices",
        });
      }

      //Commentaires
      if (
        this.moduleAccesses.some(
          (e) => e.moduleName === "commentaries" && e.access
        )
      ) {
        this.menu[1].items.push({
          label: "Commentaires",
          icon: "pi pi-fw pi-comment",
          to: "/commentaries",
        });
      }

      //Partenaires
      if (
        this.moduleAccesses.some((e) => e.moduleName === "partners" && e.access)
      ) {
        this.menu[1].items.push({
          label: "Partenaires",
          icon: "pi pi-fw pi-book",
          to: "/partners",
        });
      }

      //Dernier de la liste
      this.menu[1].items.push({
        label: "Paramètres",
        icon: "pi pi-fw pi-cog",
        to: "/settings",
      });
    });
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
          "layout-theme-light": this.$appState.theme.startsWith("saga"),
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
