import Api from "@/service/api";

export default class ModuleAccessService {
  moduleAccesses() {
    return Api(false)
      .get("/module-accesses")
      .then((res) => res.data);
  }

  moduleAccess(moduleAccessId) {
    return Api()
      .get("/module-accesses/" + moduleAccessId)
      .then((res) => res.data);
  }

  create(moduleAccess) {
    return Api()
      .post("/module-accesses", moduleAccess)
      .then((res) => res.data);
  }

  update(moduleAccess) {
    return Api()
      .put("/module-accesses/" + moduleAccess.moduleAccessId, moduleAccess)
      .then((res) => res.data);
  }

  delete(moduleAccess) {
    return Api()
      .delete("/module-accesses/" + moduleAccess.moduleAccessId)
      .then(() => moduleAccess);
  }

  getAllModuleAccesses() {
    return Api(false)
      .get("/module-accesses")
      .then((res) => res.data);
  }

  updateModuleAccesses(moduleAccesses) {
    return Api()
      .put("/module-accesses", moduleAccesses)
      .then((res) => res.data);
  }
}
