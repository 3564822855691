<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<!--<img alt="Logo" src="images/jmf_logo.jpg" /> -->
			<span>JMFrance</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
        <span>{{currentUser.login}}</span>
        <router-link :to="{name: 'login'}">
          <button class="p-link layout-topbar-button" @click="logout()">
            <i class="pi pi-power-off"></i>
          </button>
        </router-link>
			</li>
		</ul>
	</div>
</template>

<script>
import store from "@store";

export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
      logout(){
        store.dispatch("logOut");
      }
    },
  data(){
      return {
        currentUser: store?.state?.currentUser,
      }
  },
  mounted() {
    //console.log(store?.state?.currentUser)
  },
  computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>