import Api from "@/service/api";
import { formatDateTime } from "../utils/utils";

export default class SpectacleService {
  spectacles() {
    return Api()
      .get("/spectacles")
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  }

  comingSpectacles() {
    return Api(false)
      .get("/spectacles/coming")
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  }

  passedSpectacles() {
    return Api(false)
      .get("/spectacles/passed")
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  }

  spectacle(spectacleId) {
    return Api(false)
      .get("/spectacle/" + spectacleId)
      .then((res) => this.formatRes(res.data));
  }

  create(spectacle, tempId) {
    return Api()
      .post("/spectacle/" + tempId, spectacle)
      .then((res) => res.data);
  }

  update(spectacle) {
    return Api()
      .put("/spectacle/" + spectacle.spectacleId, spectacle)
      .then((res) => res.data);
  }

  delete(spectacle) {
    return Api()
      .delete("/spectacle/" + spectacle.spectacleId)
      .then(() => spectacle);
  }

  uploadPicture(spectacleId, picture) {
    let formData = new FormData();
    formData.append(
      "picture",
      new Blob([picture], {
        type: "image/jpeg",
      })
    );
    formData.append("fileName", picture.name);
    return Api()
      .post("/spectacle/" + spectacleId + "/picture", formData)
      .then((res) => res.data);
  }

  deletePicture(spectacleId) {
    return Api().delete("/spectacle/" + spectacleId + "/picture");
  }

  formatRes(e) {
    e.spectacleDates.forEach((dateSpectacle) => {
      dateSpectacle.dateSpectacle = new Date(dateSpectacle.dateSpectacle);
      dateSpectacle.dateFormatted = formatDateTime(dateSpectacle.dateSpectacle);
    });
    return e;
  }
}
