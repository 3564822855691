import axios from "axios";
import store from "../store";
//import routes from "@routes";

export default (secure = true) => {
  let header = {};
  header["Accept"] = "application/json";
  header["Content-Type"] = "application/json";
  if (secure) {
    let user = store.state.currentUser;
    if (user && user.token) header["Authorization"] = "token=" + user.token;
  }

  const instance = axios.create({
    baseURL: secure
      ? process.env.VUE_APP_BASE_API_URL
      : process.env.VUE_APP_PUBLIC_API_URL,
    withCredentials: false,
    headers: header,
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status == 403) {
        //routes.push({name: "unauthorized"});
      } else if (error.response.status == 401) {
        store.dispatch("logOutExpired");
      } else if (error.response.status == 511) {
        console.log("511 error");
        this.$router.push({ name: "dashboard" });
        store.dispatch("logOutExpired");
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
