import store from "@store";
export function removeEmptyParams(params) {
  if (!params) return params;
  for (const key of Object.keys(params)) {
    if (params[key] === "") {
      delete params[key];
    }
  }
  return params;
}
export function formatDateTime(value) {
  return new Date(value).toLocaleDateString("fr-EU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDate(value) {
  return value.toLocaleDateString("fr-EU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function formatTime(value) {
  let time = value.toTimeString().split(" ")[0];
  return time.substring(0, 5);
}

export function getModuleAccess(moduleAccessName) {
  return store?.state?.moduleAccesses?.find(
    (module) => module.moduleName === moduleAccessName && module.access === true
  );
}
