<template>
  <DataTable
    class="mt-5"
    :value="reservations"
    :paginator="true"
    :rows="10"
    :loading="loadingReservations"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[5, 10, 25]"
    currentPageReportTemplate="{first} à {last} des {totalRecords} réservations"
    responsiveLayout="scroll"
  >
    <template #header>
      <div
        class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
      >
        <h5 class="m-0">{{ title }}</h5>
      </div>
    </template>

    <template #empty>
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-ticket text-blue-500 text-xl"></i>
        <span class="ml-2">Aucune réservation trouvée</span>
      </div>
    </template>
    <Column
      field="spectacleName"
      header="Spectacle"
      :sortable="true"
      headerStyle="width:20%; min-width:10rem;"
    >
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.spectacleName }}
      </template>
    </Column>
    <Column
      field="name"
      header="Date"
      :sortable="true"
      headerStyle="width:18%; min-width:10rem;"
    >
      <template #body="slotProps">
        <span class="p-column-title"></span>
        <ak-calendar
          v-model="slotProps.data.dateSpectacle.dateSpectacle"
          :disabled="true"
          :show-time="true"
        />
      </template>
    </Column>
    <Column
      field="name"
      header="École"
      :sortable="true"
      headerStyle="width:20%; min-width:10rem;"
    >
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.name }}
      </template>
    </Column>
    <Column
      field="city"
      header="Ville"
      :sortable="true"
      headerStyle="width:20%; min-width:10rem;"
    >
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.city }}
      </template>
    </Column>
    <Column
      field="companions"
      header="Nombre d'accompagnateurs"
      :sortable="true"
    >
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.companions }}
      </template>
    </Column>

    <Column
      field="nbStudents"
      header="Nombre d'élèves"
      :sortable="true"
      headerStyle="width:10%; min-width:10rem;"
    >
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.nbStudents }}
      </template>
    </Column>

    <Column
      field="accepted"
      header="Validation"
      :sortable="true"
      style="text-align: right"
      headerStyle="width:20%; min-width:10rem;"
    >
      <template #body="slotProps">
        <Button
          icon="pi pi-check"
          class="button-custom button-valid state-badge status-valid mr-2"
          @click="openReservationConfirm(slotProps.data, 'valid')"
          v-if="slotProps.data.accepted !== 'valid'"
        />
        <Button
          icon="pi pi-envelope"
          class="button-custom button-info state-badge mr-2"
          @click="openSendMail(slotProps.data)"
        />
        <Button
          icon="pi pi-trash"
          class="button-custom button-reject state-badge status-rejected mr-2"
          @click="openReservationConfirm(slotProps.data, 'reject')"
          v-if="slotProps.data.accepted !== 'reject'"
        />
        <span
          v-if="slotProps.data.accepted == 'valid'"
          class="state-badge status-valid"
          >Validé <i class="pi pi-check ml-2" style="color: green"
        /></span>
        <span
          v-if="slotProps.data.accepted == 'reject'"
          class="state-badge status-rejected"
          >Refusé <i class="pi pi-trash ml-2" style="color: indianred"
        /></span>
      </template>
    </Column>
    <Column
      field="spectacleReservationId"
      header="Actions"
      style="text-align: right"
    >
      <template #body="slotProps">
        <Button
          v-if="getModuleAccess('prices')"
          icon="pi pi-download"
          class="p-button-rounded"
          title="Télécharger la facture"
          @click="downloadBill(slotProps.data.spectacleReservationId)"
        />
        <Button
          icon="pi pi-pencil"
          class="p-button-rounded p-button-success mt-1"
          title="Modifier"
          @click="editReservation(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>

  <Dialog
    v-model:visible="confirmValidation"
    :style="{ width: '450px' }"
    header="Confirmer"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="spectacle"
        >Voulez-vous vraiment {{ tempReservationStateTrad }} la reservation de
        l'école <b>{{ reservation.name }}</b> ?</span
      >
    </div>
    <br />
    <div class="col-12">
      <label class="mb-1">Commentaire (optionnel) :</label>
      <Textarea
        v-model="this.reservation.comment"
        :autoResize="true"
        class="col-12 mt-1"
      ></Textarea>
    </div>

    <div class="col-12" v-if="tempReservationStateTrad === 'valider'">
      <ak-input-number
        className="mt-3"
        v-model="this.reservation.freeGuide"
        label="Nombres d'accompagnateurs gratuits :"
      />
      <Slider
        v-model="this.reservation.freeGuide"
        :min="2"
        max="15"
        class="col-12 mt-1"
      />
    </div>

    <div class="p-dialog-footer">
      <Button
        label="Non"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeReservationConfirm"
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        class="p-button-text"
        @click="updateReservationState"
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="sendMailPopup"
    :style="{ width: '450px' }"
    header="Prendre contact ... "
    :modal="true"
  >
    <div class="col-12">
      <label class="mb-1"
        >Message à envoyer ({{ this.reservation?.email }}):</label
      >
      <Textarea
        v-model="this.reservation.comment"
        :autoResize="true"
        class="col-12 mt-1"
      ></Textarea>
    </div>

    <div class="p-dialog-footer">
      <Button
        label="Envoyer"
        icon="pi pi-check"
        class="p-button-text"
        @click="updateReservationState"
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="editReservationDialog"
    :style="{ width: '450px' }"
    header="Modification de la reservation"
    :modal="true"
    class="p-fluid"
  >
    <div class="row">
      <div class="col-12">
        <ak-input-text
          v-model="this.reservation.email"
          label="Email à contacter :"
          :validator="v$.reservation.email"
          :submitted="this.submitted"
          className="mt-3"
        />
      </div>
    </div>
    <div v-if="tempReservationStateTrad === 'valider'">
      <div class="row">
        <div class="col-12">
          <ak-input-number
            v-model="this.reservation.freeGuide"
            label="Nombres d'accompagnateurs gratuits :"
            :validator="v$.reservation.freeGuide"
            :submitted="this.submitted"
            className="mt-3"
          />
          <Slider
            v-model="this.reservation.freeGuide"
            :min="2"
            max="15"
            class="col-12 mt-1"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ak-input-number
          v-model="this.reservation.companions"
          label="Nombre d'accompagnant.es au total"
          :validator="v$.reservation.companions"
          :submitted="this.submitted"
          className="mt-3"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ak-input-number
          v-model="this.reservation.aesh"
          label="Dont AESH :"
          :validator="v$.reservation.aesh"
          :submitted="this.submitted"
          className="mt-3"
        />
      </div>
    </div>

    <template v-if="tempGrades.length > 0">
      <div class="row">
        <div class="col-12">
          <label class>Nombre d'élèves par classe :</label>
          <template v-for="grade in tempGrades" :key="grade.gradeId">
            <ak-input-number
              :label="grade.name"
              suffix=" élèves"
              v-model="grade.number"
              :validator="v$.tempGrades.$each"
              :submitted="this.submitted"
              class="mt-5"
            />
          </template>
        </div>
      </div>
    </template>

    <div class="p-dialog-footer">
      <Button
        label="Annuler"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeEditReservationDialog"
      />
      <Button
        label="Modifier"
        icon="pi pi-check"
        class="p-button-text"
        @click="updateReservation"
      />
    </div>
  </Dialog>
</template>

<script>
import SpectacleService from "@/service/SpectacleService";
import GradeService from "@/service/GradeService";
import SpectacleReservationService from "@/service/SpectacleReservationService";
import AkInputNumber from "@/components/input/AkInputNumber";
import AkCalendar from "@components/input/AkCalendar";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { getModuleAccess } from "../utils/utils";
import AkInputText from "./input/AkInputText.vue";

export default {
  props: {
    reservationFilters: Object,
    title: {
      type: String,
      default: "Liste des réservations",
    },
  },
  components: { AkInputNumber, AkCalendar, AkInputText },
  data() {
    return {
      value: null,
      spectacle: {},
      confirmValidation: false,
      reservation: {},
      tempReservationState: null,
      sendMailPopup: false,
      editReservationDialog: false,
      tempGrades: [],
      reservations: [],
      loadingReservations: false,
      submitted: false,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      reservation: {
        freeGuide: { required },
        companions: { required },
        aesh: { required },
      },
      tempGrades: {
        $each: helpers.forEach({
          number: { required },
        }),
      },
    };
  },

  spectacleService: null,
  spectacleReservationService: null,
  gradeService: null,
  created() {
    this.spectacleService = new SpectacleService();
    this.spectacleReservationService = new SpectacleReservationService();
    this.gradeService = new GradeService();
    this.loadingReservations = true;
    this.spectacleReservationService
      .spectacleReservations(this.reservationFilters)
      .then((data) => {
        this.reservations = data;
        this.loadingReservations = false;
      });
  },

  methods: {
    openReservationConfirm(reservation, state) {
      this.reservation = reservation;
      this.reservation.freeGuide = 2;
      this.tempReservationState = state;
      this.confirmValidation = true;
    },

    openSendMail(reservation) {
      this.reservation = reservation;
      this.sendMailPopup = true;
      this.tempReservationState = "waiting";
    },

    closeReservationConfirm() {
      this.reservation = undefined;
      this.tempReservationState = undefined;
      this.confirmValidation = false;
    },

    closeEditReservationDialog() {
      this.editReservationDialog = false;
    },

    updateReservation() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;
      const updateGradesPromise = this.gradeService.updateGrades(
        this.tempGrades
      );
      const updateSpectacleReservationPromise =
        this.spectacleReservationService.update(this.reservation);

      Promise.all([updateGradesPromise, updateSpectacleReservationPromise])
        .then(() => {
          this.reservations[
            this.findIndexById(this.reservation.spectacleReservationId)
          ] = this.reservation;

          //Somme des students de chaque grade de tempgrade
          let nbStudentsUpdated = 0;
          for (let i = 0; i < this.tempGrades.length; i++) {
            nbStudentsUpdated += this.tempGrades[i].number;
          }
          this.reservations[
            this.findIndexById(this.reservation.spectacleReservationId)
          ].nbStudents = nbStudentsUpdated;
          this.$toast.add({
            severity: "success",
            summary: "Succès",
            detail: "La reservation a été modifiée",
            life: 3000,
          });

          this.editReservationDialog = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erreur",
            detail: "Une erreur est survenue",
            life: 3000,
          });
        });
    },
    updateReservationState() {
      this.reservation.accepted = this.tempReservationState;
      this.spectacleReservationService
        .update(this.reservation)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Envoyé",
            detail: "L'email a bien été envoyé",
            life: 3000,
          });
          this.sendMailPopup = false;
          this.confirmValidation = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erreur",
            detail: "Une erreur est survenue",
            life: 3000,
          });
        });
    },

    editReservation(reservation) {
      this.reservation = { ...reservation };
      this.tempReservationState = this.reservation.accepted;
      this.gradeService
        .getGradesByReservationId(reservation.spectacleReservationId)
        .then((data) => {
          this.tempGrades = data;
          this.editReservationDialog = true;
          this.submitted = false;
        });
    },

    closeEditReservation() {
      this.reservation = undefined;
      this.editReservationDialog = false;
    },

    downloadBill(spectacleReservationId) {
      this.spectacleReservationService.getBill(spectacleReservationId);
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.reservations.length; i++) {
        if (this.reservations[i].spectacleReservationId === id) {
          index = i;
          break;
        }
      }
      return index;
    },

    getModuleAccess,
  },

  computed: {
    tempReservationStateTrad() {
      if (this.tempReservationState == "valid") {
        return "valider";
      } else return "refuser";
    },
  },

  mounted() {},
};
</script>

<style scoped>
/* Styles communs ici */
</style>
