import Api from '@services/api';
import contentDisposition from 'content-disposition';

export function downloadUtils(url, fileHash, fileName, preview) {
  return Api()
    .get(url, {
      params: {md5: fileHash},
      responseType: 'blob',
    })
    .then((response) => {

      console.log(response.headers['expires'])
      if (!preview) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        if (fileName == undefined) {
          fileName = response.headers['expires'];
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        return;
      }
      return response.data;
    })
    .catch((error) => console.log(error));
}

export function downloadUtilsDates(url, dates, fileHash, fileName, preview) {
  return Api()
    .get(url, {
      params: {md5: fileHash, start: dates.start, end: dates.end},
      responseType: 'blob',
    })
    .then((response) => {
      if (!preview) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        if (fileName == undefined) {
          fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        return;
      }
      return response.data;
    })
    .catch((error) => console.log(error));
}
