import Api from "@/service/api";

export default class GradeService {
    grades() {
        return Api().get("/grades").then(res => res.data);
    }
    grade(gradeId) {
        return Api().get("/grade/" + gradeId).then(res => res.data);
    }
    create(grade) {
        return Api().post("/grade", grade).then(res => res.data);
    }
    update(grade) {
        return Api().put("/grade/" + grade.gradeId, grade).then(res => res.data);
    }
    delete(grade) {
        return Api().delete("/grade/" + grade.gradeId).then(() => grade);
    }
    getGradesByReservationId(reservationId) {
        return Api().get("/grades/reservations/" + reservationId).then(res => res.data);
    }
    updateGrades(grades) {
        return Api().put("/grades", grades).then(res => res.data);
    }
}
